import type { FC, PropsWithChildren } from 'react';

import { Pagination } from '../pagination';
import type { PaginationControlProps } from '../pagination';

import * as styles from './styles';
import { TextSm } from '../typography';

export const Header: FC<PropsWithChildren & PaginationControlProps> = ({
	children,
	...props
}) => {
	return (
		<div className={styles.header}>
			<div className={styles.title}>
				<TextSm as="p" weight="bold">
					{children}
				</TextSm>
			</div>
			<Pagination {...props} />
		</div>
	);
};

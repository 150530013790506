import { useAutofocusWithin } from '@smile/ds/a11y';
import { PrimaryButton } from '@smile/ds/actions';
import type { FC, ReactNode } from 'react';

import { Partnership } from '../partnership';

import * as styles from './styles';
import { H1, TextMd } from '@smile/ds/typography';
import { Surface } from '@smile/ds/surface';
import { useScrollControl } from '../utils/scroll-control';

export const SplashScreen: FC<{
	text: ReactNode;
	subtext: ReactNode;
	cta?: string;
	image: string;
	onGetStarted?(): void;
	brand: string;
	brandColor?: string;
}> = ({
	brand,
	brandColor,
	text,
	subtext,
	onGetStarted,
	image,
	cta = 'Show me my offers',
}) => {
	const buttonsRef = useAutofocusWithin<HTMLDivElement>(true);

	const onScrollDown = useScrollControl();

	return (
		<div className={styles.component}>
			<div className={styles.presentation}>
				<img
					src={image}
					width={'100%'}
					height={'100%'}
					alt=""
					role="presentation"
				/>
			</div>
			<Surface as="div" className={styles.textWrapper}>
				<div className={styles.logo}>
					<Partnership
						brand={brand}
						logoWidth={70}
						partnerColor={brandColor || ''}
					/>
				</div>
				<H1 className={styles.text} as="h2">
					{text}
				</H1>
				<TextMd className={styles.subText} as="p">
					{subtext}
				</TextMd>
				<div ref={buttonsRef} className={styles.buttonsGroup}>
					<PrimaryButton
						onClick={() => {
							onScrollDown?.();
							onGetStarted?.();
						}}
						actionName="get-started"
						autoFocus
					>
						{cta}
					</PrimaryButton>
				</div>
			</Surface>
		</div>
	);
};

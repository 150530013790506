import { fromMarkAst } from '@theway/markdown-ui';
import {
	composeFactory,
	createSlotRender,
	renderer,
} from '@theway/type-broker';

import { useLinkTrackers, WithLinkTracker } from '../../analytics/tracker-link';
import { CouponFetcher } from '../../components/coupon-fetcher';
import { GiftcardFetcher } from '../../components/giftcard-fetcher';
import { SubscriptionController } from '../../components/subscription-controller';
import { ThanksGiveawayController } from '../../components/thanks-giveaway-controller';
import { InlineLink } from '@smile/ds/actions';
import { DIRECT_CODE } from '@thanks/impression-type';

const offerRenderer = renderer(
	'offer',
	() => import('@platycercus/widget/offer'),
	(_type, { OfferLink }, data, props) => (
		<WithLinkTracker url={data.href}>
			{(href) => (
				<OfferLink
					{...props}
					{...data}
					image={data.mg?.image_mobile || ''}
					href={href}
					text={fromMarkAst(data.text)}
					subtext={fromMarkAst(data.subtext)}
					disclaimer={fromMarkAst(data.disclaimer)}
					terms={data.termsText}
					emailNotification={false}
					logo={data.img?.active || ''}
					logoBg={data.img?.bg || ''}
					logoStroke={data.img?.stroke || ''}
				/>
			)}
		</WithLinkTracker>
	),
);

const doubleOfferRenderer = renderer(
	'dual-offer',
	() => import('@platycercus/widget/dual-offer'),
	(_type, { OfferDual }, data, props) => (
		<OfferDual
			{...props}
			{...data}
			image={data.mg?.image_mobile || ''}
			text={fromMarkAst(data.text)}
			subtext={fromMarkAst(data.subtext)}
			disclaimer={fromMarkAst(data.disclaimer)}
			firstOffer={{
				caption: data.linkCaption,
				href: useLinkTrackers(data.href, { order: 1 }),
				advertiserId: data.dualSettings.firstOffer.advertiserId,
				bgColor: data.dualSettings.firstOffer.actionBackgroundColor,
				textColor: data.dualSettings.firstOffer.actionTextColor,
			}}
			secondOffer={{
				caption: data.secondaryLinkCaption,
				href: useLinkTrackers(data.href, { order: 2 }),
				advertiserId: data.dualSettings.secondOffer.advertiserId,
				bgColor: data.dualSettings.secondOffer.actionBackgroundColor,
				textColor: data.dualSettings.secondOffer.actionTextColor,
			}}
			terms={data.termsText}
			emailNotification={false}
			logo={data.img?.active || ''}
			logoBg={data.img?.bg || ''}
			logoStroke={data.img?.stroke || ''}
		/>
	),
);

const codeRenderer = renderer(
	'code',
	() => import('@platycercus/widget/offer'),
	(_type, { OfferCoupon, OfferRevealCode }, data, props, impression) => (
		<CouponFetcher
			offer={data}
			impression={impression.impressionId}
			piiRegion={impression.piiRegion}
		>
			{(code, getCode, status, href) =>
				code !== DIRECT_CODE ? (
					<OfferRevealCode
						{...props}
						{...data}
						href={href}
						code={code}
						getCode={getCode}
						status={status}
						image={data.mg?.image_mobile || ''}
						text={fromMarkAst(data.text)}
						subtext={fromMarkAst(data.subtext)}
						disclaimer={fromMarkAst(data.disclaimer)}
						cta={data.linkCaption}
						terms={data.termsText}
						emailNotification={false}
						logo={data.img?.active || ''}
						logoBg={data.img?.bg || ''}
						logoStroke={data.img?.stroke || ''}
					/>
				) : (
					<OfferCoupon
						{...props}
						{...data}
						href={href}
						image={data.mg?.image_mobile || ''}
						text={fromMarkAst(data.text)}
						subtext={fromMarkAst(data.subtext)}
						disclaimer={fromMarkAst(data.disclaimer)}
						cta={data.linkCaption}
						terms={data.termsText}
						emailNotification={false}
						logo={data.img?.active || ''}
						logoBg={data.img?.bg || ''}
						logoStroke={data.img?.stroke || ''}
					/>
				)
			}
		</CouponFetcher>
	),
);

const giftcardRenderer = renderer(
	'transaction',
	() => import('@platycercus/widget/giftcard-offer'),
	(_type, { OfferGiftCard }, data, props, impression) => (
		<GiftcardFetcher
			offer={data}
			impression={impression.impressionId}
			piiRegion={impression.piiRegion}
			onNext={props.onNext}
		>
			{(
				{ getCode, usesExternalWindow },
				// { email },
			) => (
				<OfferGiftCard
					{...props}
					{...data}
					image={data.mg?.image_mobile || ''}
					text={fromMarkAst(data.text)}
					subtext={fromMarkAst(data.subtext)}
					disclaimer={fromMarkAst(data.disclaimer)}
					usesExternalWindow={usesExternalWindow || false}
					getPaymentInformation={getCode}
					cta={data.linkCaption}
					terms={data.termsText}
					emailNotification={false}
					logo={data.img?.active || ''}
					logoBg={data.img?.bg || ''}
					logoStroke={data.img?.stroke || ''}
				/>
			)}
		</GiftcardFetcher>
	),
);

const subscriptionRenderer = renderer(
	'subscription',
	() => import('@platycercus/widget/subscription-offer'),
	(_type, { OfferSubscription }, data, props, impression) => {
		return (
			<SubscriptionController
				offer={data}
				impression={impression.impressionId}
				piiRegion={impression.piiRegion}
				onNext={props.onNext}
			>
				{({ prefilled, processData }) => (
					<OfferSubscription
						{...props}
						{...data}
						image={data.mg?.image_mobile || ''}
						text={<>{fromMarkAst(data.text)}</>}
						subtext={fromMarkAst(data.subtext)}
						disclaimer={fromMarkAst(data.disclaimer)}
						fieldset={data.subscription.collect}
						subscriptionName={data.subscription.name}
						TNC={data.subscription.link}
						thanksTNC={data.subscription.thanksLink}
						cta={data.subscription.actionText}
						{...prefilled}
						onInformationEnter={processData}
						terms={data.termsText}
						emailNotification={false}
						logo={data.img?.active || ''}
						logoBg={data.img?.bg || ''}
						logoStroke={data.img?.stroke || ''}
					/>
				)}
			</SubscriptionController>
		);
	},
);

const HELLO_FRESH_US_ID = '8dd91283-bf80-435e-823e-31bd2c46d74f';
const FACTOR_US_ID = 'f3fffc27-462d-4009-8f89-9ae9a545af9c';

const thanksGiveawayRenderer = renderer(
	'thanks-giveaway',
	() => import('@platycercus/widget/offer'),
	(_type, { OfferGiveaway }, data, props, impression) => {
		const actionName = 'Enter now';

		return (
			<WithLinkTracker url={data.href}>
				{(href) => (
					<ThanksGiveawayController
						impression={impression}
						offer={data}
						onNext={props.onNext}
					>
						{({ processData, prefilled }) => (
							<OfferGiveaway
								{...props}
								{...data}
								image={data.mg?.image_mobile || ''}
								text={fromMarkAst(data.text)}
								subtext={fromMarkAst(data.subtext)}
								disclaimer={fromMarkAst(data.disclaimer)}
								eulaOverride={
									<>
										By clicking {actionName} you agree to
										share your info with{' '}
										<InlineLink href="https://thanks.co">
											Thanks
										</InlineLink>{' '}
										for use in accordance with their Privacy
										Policy.
										<br />
										{[
											HELLO_FRESH_US_ID,
											FACTOR_US_ID,
										].includes(data.advertiserId) &&
											'No purchase necessary to enter or win. Making a purchase will not improve your chances of winning.'}
									</>
								}
								cta={actionName}
								{...prefilled}
								onInformationEnter={(data, options) => {
									window.open(href);

									return processData(data, options);
								}}
								terms={data.termsText}
								emailNotification={false}
								logo={data.img?.active || ''}
								logoBg={data.img?.bg || ''}
								logoStroke={data.img?.stroke || ''}
							/>
						)}
					</ThanksGiveawayController>
				)}
			</WithLinkTracker>
		);
	},
);

const factRenderer = renderer(
	'fact',
	() => import('@platycercus/widget/exit'),
	(
		_type,
		{ ExitScreen },
		data,
		props,
		_impression,
		ownProps: { onClose(): void },
	) => (
		<ExitScreen
			onClose={ownProps.onClose}
			{...props}
			image={data.mg?.image_mobile || ''}
			cta={data.actionText}
			text={fromMarkAst(data.text)}
			subtext={fromMarkAst(data.subText)}
		/>
	),
);

const TYPES = composeFactory([
	offerRenderer,
	doubleOfferRenderer,
	codeRenderer,
	giftcardRenderer,
	subscriptionRenderer,
	factRenderer,
	thanksGiveawayRenderer,
] as const);

export const slotRender = createSlotRender(TYPES);

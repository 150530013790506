import classNames from 'clsx';

import type { ReactNode } from 'react';

import { useColorScheme } from '../scheme/color';

import * as contained from './containedStyles';
import * as inline from './inlineStyles';

import type { VariantType, ColorType } from './types';
import { VisuallyHidden } from '@smile/ds/a11y';

const useActionClassName = (
	variant: VariantType,
	color: ColorType,
	icon?: ReactNode,
	iconBefore?: ReactNode,
	iconAfter?: ReactNode,
	fullWidth?: boolean,
	underlined?: boolean,
) => {
	const styles = { contained, inline };

	const themeMode = useColorScheme({
		light: styles[variant].lightTheme,
		dark: styles[variant].darkTheme,
		contrast: styles[variant].contrastTheme,
	});

	return classNames(
		styles[variant].component,
		styles[variant][color],
		icon ? styles[variant].icon : undefined,
		iconBefore ? styles[variant].iconBefore : undefined,
		iconAfter ? styles[variant].iconAfter : undefined,
		!icon && fullWidth && styles[variant].fullWidth,
		underlined && styles.inline.underlined,
		themeMode,
	);
};

export const useActionProperty = (
	children: ReactNode,
	variant: VariantType,
	color: ColorType,
	icon?: ReactNode,
	iconBefore?: ReactNode,
	iconAfter?: ReactNode,
	fullWidth?: boolean,
	underlined?: boolean,
) => {
	const className = useActionClassName(
		variant,
		color,
		icon,
		iconBefore,
		iconAfter,
		fullWidth,
		underlined,
	);
	const content = (
		<>
			{iconBefore}
			{icon ? (
				<>
					<VisuallyHidden>{children}</VisuallyHidden>
					{icon}
				</>
			) : (
				children
			)}
			{iconAfter}
		</>
	);

	return { className, content };
};

import { css } from '@linaria/core';

export const lightTheme = css`
	--color: #333333;
`;

export const darkTheme = css``;

export const contrastTheme = css``;

export const component = css`
	position: relative;

	font: inherit;

	color: inherit;

	border: 0;
	background-color: transparent;

	transition-timing-function: linear;
	transition-duration: 200ms;
	transition-property: all;

	&:hover,
	[data-focus-visible] &:focus-visible {
		color: var(--color);
	}

	&:disabled {
		opacity: 0.5;
	}
`;

export const primary = css``;

export const secondary = css``;

export const icon = css``;

export const iconBefore = css``;

export const iconAfter = css``;

export const fullWidth = css``;

export const underlined = css`
	text-decoration: underline;
`;

import type { ImpressionSequence, OfferSlot } from '@thanks/impression-type';
import { renderAstToString } from '@theway/markdown/from-ast';
import { useEffect, useRef, useState } from 'react';

import { analytics } from '../analytics';

import { useSyncingRef, useVisibilityState } from './hooks';

const getAdText = (slide: OfferSlot): string => {
	if ('title' in slide.data) {
		return renderAstToString(slide.data.title);
	}

	if ('text' in slide.data) {
		return renderAstToString(slide.data.text);
	}

	return 'no text';
};

export const getAdTrackingAttributes = (
	slide: OfferSlot,
	step: number,
	impression: ImpressionSequence,
) => ({
	offerId: slide.data.experienceId,
	publisherId: impression.partnerSiteId,
	publisherName: '',

	position: step,
	type: slide.type,
	// @deprecated
	// slug: 'id' in slide.data ? slide.data.id : 'no-id',
	trackId: 'trackId' in slide.data ? slide.data.trackId : undefined,

	order: 0,
	variant: 'variant' in slide.data ? slide.data.variant : 1,

	advertiserId: 'advertiserId' in slide.data ? slide.data.advertiserId : '',
	advertiserName: slide.data.partnerName,
	// @deprecated
	partner: slide.data.partnerName,

	adName: 'name' in slide.data ? slide.data.name : 'no-name',
	adText: getAdText(slide),
	adSubtext:
		'subtext' in slide.data
			? renderAstToString(slide.data.subtext)
			: undefined,

	// @ts-expect-error - actionText is from Splash while linkCaption is from Offer
	adCTA: slide.data.actionText || slide.data.linkCaption,

	category: 'category' in slide.data ? slide.data.category : [],

	timeVisible: 0,

	experiment: impression.experiment || 'default',
});

export const useExposureTracking = (
	step: number,
	slide: OfferSlot,
	impression: ImpressionSequence,
) => {
	useEffect(() => {
		if (slide.data.experienceId) {
			analytics.phaseMetric('first-impression');
		} else {
			analytics.phaseMetric('displayed');
		}
	}, [Boolean(slide)]);

	const documentVisible = useVisibilityState();
	const visibleRef = useSyncingRef(documentVisible);

	useEffect(() => {
		// send every 10 seconds and only for the first screen
		if (step) {
			return () => null;
		}

		const int = setInterval(() => {
			if (visibleRef.current) {
				analytics.heartBeat(
					step,
					slide?.type ?? 'welcome',
					slide?.data.experienceId || 'unrelated',
				);
			}
		}, 10000);

		return () => {
			clearInterval(int);
		};
	}, [step]);

	const lastSlideExposure = useRef(0);

	useEffect(() => {
		if (slide) {
			lastSlideExposure.current = Date.now();

			analytics.ad.exposure(slide.data.experienceId ?? 'welcome', {
				...getAdTrackingAttributes(slide, step, impression),
			});
		}
	}, [slide]);

	const [viewTracker] = useState<Record<number, boolean>>({});

	useEffect(() => {
		if (slide && documentVisible && !viewTracker[step]) {
			viewTracker[step] = true;
			// TODO this may still be called if the popup doesn't show in the cooldown window, there is a race condition between removing iframe and this
			analytics.ad.view(slide.data.experienceId ?? 'welcome', {
				...getAdTrackingAttributes(slide, step, impression),
			});
		}
	}, [slide, documentVisible]);
};

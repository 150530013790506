import { useFeatureFlag } from '@smile/experimentation';
import type { ReactNode, FC } from 'react';
import { clsx } from 'clsx';
import * as styles from './layout-styles';

export const NativePositionMobileWrapper: FC<{
	children: ReactNode;
}> = ({ children }) => {
	return (
		<div
			className={clsx(
				'widget-top-wrapper',
				styles.animatedAppearanceFromBottom,
			)}
			style={{
				margin: 'auto',

				width: '100%',
				height: '100%',
				alignContent: 'end',
			}}
		>
			{children}
		</div>
	);
};

export const MobileWrapper: FC<{
	children: ReactNode;
	offsetTop?: number | undefined;
}> = ({ children, offsetTop }) => {
	if (useFeatureFlag('use-native-position')) {
		return (
			<NativePositionMobileWrapper>
				{children}
			</NativePositionMobileWrapper>
		);
	}

	return (
		<div
			className="widget-top-wrapper"
			style={{
				margin: 'auto',
				marginTop: offsetTop ?? 70,
			}}
		>
			{children}
		</div>
	);
};

// used by white-ibis
export const DesktopWrapper652: FC<{
	children: ReactNode;
	offsetTop?: number | undefined;
}> = ({ children, offsetTop }) => (
	<div
		className="widget-top-wrapper"
		style={{
			margin: 'auto',
			marginTop: offsetTop ?? 100,
		}}
	>
		{children}
	</div>
);

export const DesktopWrapper: FC<{
	children: ReactNode;
	offsetTop?: number | undefined;
}> = ({ children, offsetTop }) => (
	<div
		className="widget-top-wrapper"
		style={{
			margin: 'auto',
			marginTop: offsetTop ?? 100,
		}}
	>
		{children}
	</div>
);

import { type FC, useCallback, useState } from 'react';
import { H2, TextMd } from '@smile/ds/typography';
import {
	ContextContentTeleporter,
	ContextPresentationTeleporter,
	ContextBadgeTeleporter,
} from '../control/teleporters';

import * as styles from './styles';
import { PrimaryButton, SecondaryButton } from '@smile/ds/actions';
import { ArrowRight } from '@smile/ds/icons/ArrowRight';
import { Surface } from '@smile/ds/surface';
import { Partnership } from './Partnership';

import clsx from 'clsx';

export type CharityOrgType = {
	name: string;
	squareLogo: string;
	rectLogo: string;
	imageXs: string;
	image: string;
	purpose: string;
	exitText: string;
	logoBg: string;
	logoStroke?: string;
};

type CommonProps = {
	amount: number;
	onClose(): void;
	onBackToOffer(): void;
};

type CharityProps = {
	period: string;
	charityList: CharityOrgType[];
	displayCharity: boolean;
	onDonateToCharity(charityName: string): void;
} & CommonProps;

const SingleContent: FC<
	{
		logo: string;
		name: string;
		period: string;
	} & CommonProps
> = ({ logo, name, amount, period, onClose, onBackToOffer }) => {
	const title = `We’re donating $${amount} every ${period} to the ${name} on your behalf`;

	return (
		<div className={styles.single}>
			<Partnership brand={logo} />
			<TextMd className={styles.subtext} as="span">
				Feel good moment
			</TextMd>
			<H2 className={styles.text} as="h2">
				{title}
			</H2>
			<div className={styles.buttonsGroup}>
				<PrimaryButton onClick={onClose}>
					Enjoy the rest of your day
				</PrimaryButton>
				<SecondaryButton
					onClick={onBackToOffer}
					iconAfter={<ArrowRight />}
				>
					Back to offers
				</SecondaryButton>
			</div>
		</div>
	);
};

const MultiContent: FC<
	{
		charityList: CharityOrgType[];
		onDonateClick(charity: CharityOrgType): void;
		displaySecondScreen: boolean;
		activeCharity: CharityOrgType | undefined;
	} & CommonProps
> = ({
	amount,
	charityList,
	onBackToOffer,
	onDonateClick,
	onClose,
	displaySecondScreen,
	activeCharity,
}) => {
	const title = `We’re donating $${amount}. Choose where it goes!`;

	return displaySecondScreen && activeCharity ? (
		<div className={styles.exit}>
			{activeCharity && <Partnership brand={activeCharity.rectLogo} />}
			<TextMd className={styles.subtext} as="span">
				You’ve chosen the {activeCharity.name}
			</TextMd>
			<H2 className={styles.text} as="h2">
				{activeCharity.exitText}
			</H2>
			<div className={styles.buttonsGroup}>
				<PrimaryButton onClick={onClose}>
					Enjoy the rest of your day
				</PrimaryButton>
			</div>
		</div>
	) : (
		<div className={styles.multi}>
			<H2 className={styles.text} as="h2">
				{title}
			</H2>
			<ul className={styles.list}>
				{charityList?.map((charity) => (
					<li className={styles.item} key={charity.name}>
						<button
							className={clsx(
								styles.donate,
								charity === activeCharity && styles.active,
							)}
							onClick={() => onDonateClick(charity)}
						>
							<div className={styles.donateImage}>
								<img
									style={{
										...styles.donateBgVar(charity.logoBg),
										...styles.donateStrokeVar(
											charity.logoStroke ||
												charity.logoBg,
										),
									}}
									src={charity.squareLogo}
									width="40"
									height="40"
									alt=""
									role="presentation"
								/>
							</div>
							<div className={styles.org}>
								<TextMd as="span">{charity.purpose}</TextMd>
								<TextMd as="span" color="muted">
									{charity.name}
								</TextMd>
							</div>
						</button>
					</li>
				))}
			</ul>
			<div className={styles.buttonsGroup}>
				<SecondaryButton
					onClick={onBackToOffer}
					iconAfter={<ArrowRight />}
				>
					Back to offers
				</SecondaryButton>
			</div>
		</div>
	);
};

export const MobileCharity: FC<CharityProps> = ({
	amount,
	period,
	charityList,
	onClose,
	displayCharity,
	onBackToOffer,
	onDonateToCharity,
}) => {
	const isMulti = charityList.length > 1;

	const [activeCharity, setActiveCharity] = useState<
		CharityOrgType | undefined
	>(undefined);
	const [displaySecondScreen, setDisplaySecondScreen] =
		useState<boolean>(false);

	const handleCharityClick = useCallback((charity: CharityOrgType) => {
		onDonateToCharity && onDonateToCharity(charity.name);
		setActiveCharity(charity);
		setTimeout(() => {
			setDisplaySecondScreen(true);
		}, 800);
	}, []);

	return (
		<Surface
			className={clsx(styles.mobile, displayCharity && styles.visible)}
			as="div"
			color="secondary"
		>
			{isMulti ? (
				<>
					{displaySecondScreen && activeCharity && (
						<div className={styles.image}>
							<img
								src={activeCharity.imageXs}
								alt=""
								role="presentation"
								width="100%"
								height="100%"
							/>
						</div>
					)}
					<MultiContent
						amount={amount}
						charityList={charityList}
						onBackToOffer={onBackToOffer}
						onDonateClick={handleCharityClick}
						onClose={onClose}
						displaySecondScreen={displaySecondScreen}
						activeCharity={activeCharity}
					/>
				</>
			) : (
				<>
					<div className={styles.image}>
						<img
							src={charityList[0].imageXs}
							alt=""
							role="presentation"
							width="100%"
							height="100%"
						/>
					</div>
					<SingleContent
						amount={amount}
						period={period}
						logo={charityList[0].rectLogo}
						name={charityList[0].name}
						onClose={onClose}
						onBackToOffer={onBackToOffer}
					/>
				</>
			)}
		</Surface>
	);
};

export const DesktopCharity: FC<CharityProps> = ({
	amount,
	period,
	charityList,
	onBackToOffer,
	onClose,
	onDonateToCharity,
}) => {
	const isMulti = charityList.length > 1;

	const [activeCharity, setActiveCharity] = useState<
		CharityOrgType | undefined
	>(undefined);
	const [displaySecondScreen, setDisplaySecondScreen] =
		useState<boolean>(false);

	const handleCharityClick = useCallback((charity: CharityOrgType) => {
		onDonateToCharity && onDonateToCharity(charity.name);
		setActiveCharity(charity);
		setTimeout(() => {
			setDisplaySecondScreen(true);
		}, 800);
	}, []);

	return (
		<>
			<ContextPresentationTeleporter.Source>
				{isMulti ? (
					<>
						{displaySecondScreen && activeCharity ? (
							<div className={styles.image}>
								<img
									src={activeCharity.image}
									alt=""
									role="presentation"
									width="100%"
									height="100%"
								/>
							</div>
						) : (
							<div className={styles.empty} />
						)}
					</>
				) : (
					<div className={styles.image}>
						<img
							src={charityList[0].image}
							alt=""
							role="presentation"
							width="100%"
							height="100%"
						/>
					</div>
				)}
			</ContextPresentationTeleporter.Source>
			<ContextContentTeleporter.Source>
				<Surface as="div" className={styles.desktop} color="secondary">
					{isMulti ? (
						<MultiContent
							amount={amount}
							charityList={charityList}
							onBackToOffer={onBackToOffer}
							onDonateClick={handleCharityClick}
							onClose={onClose}
							displaySecondScreen={displaySecondScreen}
							activeCharity={activeCharity}
						/>
					) : (
						<SingleContent
							amount={amount}
							period={period}
							logo={charityList[0].rectLogo}
							name={charityList[0].name}
							onClose={onClose}
							onBackToOffer={onBackToOffer}
						/>
					)}
				</Surface>
			</ContextContentTeleporter.Source>
			<ContextBadgeTeleporter.Source>
				<span />
			</ContextBadgeTeleporter.Source>
		</>
	);
};

import { InlineButton, InlineLink } from '@smile/ds/actions';
import { useControlModal } from '@smile/ds/modal';

import type { FC } from 'react';
import { lazy, Suspense } from 'react';

import { Asterisk } from '../control/state-controller';

import * as styles from './styles';
import clsx from 'clsx';
import { TextXs } from '../typography';

const Terms = lazy(() => import('../terms'));

type TermsType = {
	text: string;
	logo: string;
	logoBg: string;
	logoStroke?: string | undefined;
};

export type FooterProps = {
	terms: TermsType | undefined;
	displayCharity?: boolean;
};

// FIXME: extract into shared constants
const THANKS_PRIVACY_POLICY_URL = 'https://www.thanks.co/terms-and-privacy';
const THANKS_URL = 'https://www.thanks.co';

export const Footer: FC<FooterProps> = ({ terms, displayCharity = false }) => {
	const hasTerms = Boolean(terms);

	const termsControl = useControlModal();

	return (
		<>
			<TextXs
				as="footer"
				className={clsx(
					styles.footer,
					displayCharity && styles.emphasised,
				)}
				color="muted"
			>
				{displayCharity ? null : (
					<>
						{hasTerms ? (
							<>
								<span>
									<Asterisk />
									<InlineButton
										onClick={termsControl.toggle}
										actionName="terms-and-conditiond"
										underlined
									>
										T&C's Apply
									</InlineButton>
								</span>
								|
							</>
						) : null}
					</>
				)}
				<InlineLink
					href={THANKS_URL}
					newTab
					actionName="thanksad"
					underlined
				>
					Powered by Thanks
				</InlineLink>
				|
				<InlineLink
					href={THANKS_PRIVACY_POLICY_URL}
					newTab
					actionName="thanksad privacy-policy"
					underlined
				>
					Terms & Privacy
				</InlineLink>
			</TextXs>
			{terms && termsControl.modal.defined ? (
				<Suspense fallback="">
					<Terms
						logo={terms.logo}
						logoBg={terms.logoBg}
						control={termsControl.modal}
						onClose={termsControl.close}
					>
						{terms.text}
					</Terms>
				</Suspense>
			) : null}
		</>
	);
};

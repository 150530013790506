import { css } from '@linaria/core';

export const list = css`
	display: grid;
	grid-auto-flow: column;
	justify-content: space-between;
`;

export const action = css`
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 54px;
	height: 54px;

	font-family: inherit;
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
	color: #333333;

	border-radius: 50%;
	border: 1px solid #b2b2b2;

	background-color: #f2f2f2;

	&:disabled {
		cursor: initial;
	}

	& img {
		width: 100%;
		height: 100%;

		object-fit: contain;
	}
`;

export const visited = css`
	border-color: #b2b2b2;
	background-color: #fff;
`;

export const active = css`
	border-color: #000000;
	background-color: #fff;
`;
